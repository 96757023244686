@font-face {
    font-family: "iconfont"; /* Project id 3307923 */
    src: url('//at.alicdn.com/t/font_3307923_nc2f0m45gl.woff2?t=1649294814198') format('woff2'),
    url('//at.alicdn.com/t/font_3307923_nc2f0m45gl.woff?t=1649294814198') format('woff'),
    url('//at.alicdn.com/t/font_3307923_nc2f0m45gl.ttf?t=1649294814198') format('truetype');
}

.iconfont {
    font-family: "iconfont" !important;
    font-size: 16px;
    font-style: normal;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon-duihao:before {
    content: "\e62f";
}

.icon-V:before {
    content: "\e702";
}
